<template>
	<v-container>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-card-title primary-title>
			<div class="headline font-weight-bold text-capitalize">
				<v-btn icon @click="$router.go(-1)">
					<v-icon>mdi-arrow-left</v-icon>
				</v-btn>
				{{ "crud.revisions" | trans }}
				{{ label | trans }}
				<v-icon dark left>history</v-icon>
			</div>
		</v-card-title>
		<v-timeline align-top dense>
			<template v-for="(group, key) in groups">
				<v-timeline-item class="my-2" right :key="key" large icon="event" color="pink">
					<v-layout row wrap>
						<h3 class="py-3">{{ key | moment("D MMMM Y") }}</h3>
						<v-spacer></v-spacer>
					</v-layout>
					<v-divider></v-divider>
				</v-timeline-item>
				<template v-for="rev in group">
					<v-timeline-item class="my-2" large :key="rev.id">
						<template v-slot:icon>
							<v-avatar v-if="rev.user">
								<img :src="rev.user.imagen" />
							</v-avatar>
							<v-icon v-else dark>event</v-icon>
						</template>
						<v-card>
							<v-layout row pa-4>
								<v-flex xs8 sm9 md10 lg11>
									<div row class="body-1">
										<span v-if="rev.user" class="primary--text" v-text="rev.user.full_name"></span>
										<span v-else> {{ "unknown" | trans }} </span>
										<template v-if="rev.key === 'created_at' && rev.old_value == null">
											{{ "crud.created_this" | trans }}
											{{ "crud.item" | trans }}
										</template>
										<template v-else>
											<span>
												{{ "crud.changed_the" | trans }}
											</span>
											<strong> {{ rev.fieldName ? rev.fieldName : rev.key }}: </strong>
										</template>
									</div>
									<template v-if="!(rev.fieldName === 'created_at' && rev.old_value == null)">
										<div class="body-1 ellipsed">
											<strong class="text-capitalize">{{ "crud.from" | trans }}: </strong>
											<span v-if="rev.old_value">{{ rev.old_value | truncate(300) }}</span>
											<span v-else class="grey--text">{{ "NULL" }}</span>
										</div>
										<div class="body-1 ellipsed">
											<strong class="text-capitalize">{{ "crud.to" | trans }}: </strong>
											<span v-if="rev.new_value">{{ rev.new_value | truncate(300) }}</span>
											<span v-else class="grey--text">{{ "NULL" }}</span>
										</div>
									</template>
								</v-flex>
								<v-flex xs4 sm3 md2 lg1 justify-end layout class="text-right layout column" pt-2>
									<div>
										<v-icon small dark>access_time</v-icon>
										<span class="body-1"> {{ rev.created_at | moment("hh:mm:ss A") }}</span>
									</div>
									<!-- <div>
										<v-btn small text right color="primary">
											{{ "crud.undo" | trans }}
										</v-btn>
									</div> -->
								</v-flex>
							</v-layout>
						</v-card>
					</v-timeline-item>
				</template>
			</template>
		</v-timeline>
		<v-card-title primary-title>
			<div class="subhead text-center text-capitalize">
				{{ "crud.no_revisions" | trans }}
			</div>
		</v-card-title>
	</v-container>
</template>
<script>
import moment from "moment";
import _ from "lodash";
export default {
	mounted() {
		this.getData();
	},
	methods: {
		async getData() {
			this.loading = true;
			this.revisions = (
				await this.api.get(`revisions/model/${this.model}/${this.id}/history?include=user&limit=100`)
			).data;
			this.groups = _.groupBy(this.revisions, function (rev) {
				return moment(rev.created_at).local().startOf("day").format("YYYY-MM-DD");
			});
			this.loading = false;
		},
	},
	watch: {
		model: "getData",
		id: "getData",
	},
	data() {
		return {
			loading: false,
			revisions: [],
			groups: {},
		};
	},
	props: {
		model: {
			type: String,
			required: true,
		},
		id: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			default: "",
		},
	},
};
</script>
